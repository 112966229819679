@import "../../_common";

$border-thin-width: 16px;
$border-thick-width: 60px;

$smartphone-portrait-width: 360px;
$smartphone-portrait-height: 640px;
$tablet-portrait-width: 768px;
$tablet-portrait-height: 1024px;
$desktop-width: 1366px;
$desktop-height: 800px;

body.preview--is-modal-open {
  overflow: hidden;
}

.preview {
  &-overlay {
    & .preview-content {
      & .types {
        display: grid;
        grid-template-columns: repeat(auto-fit, 50px);
        justify-content: center;
        margin-bottom: 10px;
      }

      & .device {
        border: $border-thin-width $primary-accent-dark solid;
        border-radius: 36px;
        background: $white;

        & .iframe {
          width: 100%;
          height: 100%;
          border: none;
          overflow-y: scroll;
        }
      }

      &--is-smartphone-portrait {
        width: $smartphone-portrait-width + 2 * $border-thin-width;

        & .device {
          width: $smartphone-portrait-width;
          height: $smartphone-portrait-height;
          border-top-width: $border-thick-width;
          border-bottom-width: $border-thick-width;
        }
      }

      &--is-smartphone-landscape {
        width: $smartphone-portrait-height + 2 * $border-thick-width;

        & .device {
          width: $smartphone-portrait-height;
          height: $smartphone-portrait-width;
          border-left-width: $border-thick-width;
          border-right-width: $border-thick-width;
        }
      }

      &--is-tablet-portrait {
        width: $tablet-portrait-width + 2 * $border-thin-width;

        & .device {
          width: $tablet-portrait-width;
          height: $tablet-portrait-height;
          border-top-width: $border-thick-width;
          border-bottom-width: $border-thick-width;
        }
      }

      &--is-tablet-landscape {
        width: $tablet-portrait-height + 2 * $border-thick-width;

        & .device {
          width: $tablet-portrait-height;
          height: $tablet-portrait-width;
          border-left-width: $border-thick-width;
          border-right-width: $border-thick-width;
        }
      }

      &--is-desktop {
        width: $desktop-width + 2 * $border-thin-width;

        & .device {
          width: $desktop-width;
          height: $desktop-height;
          border-radius: 0;
        }
      }
    }
  }
}
