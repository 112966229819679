@import "../__common";

.group {
  position: relative;
  margin-bottom: 10px;
  border-bottom: 1px solid $divider;
  input[type="checkbox"] {
    position: relative;
    top: 15px;
  }
}
input {
  font-size: 18px;
  padding: 33px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  background-color: transparent;
}
input:focus {
  outline: none;
}

label {
  color: $secondary-accent-dark;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  -o-transition: all 0.2s ease all;
  -ms-transition: all 0.2s ease all;
}
input:focus ~ label,
input:not(:placeholder-shown) ~ label {
  top: 9px;
}
.bar {
  position: relative;
  display: block;
  width: 100%;
}
.bar:before,
.bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: $primary;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  -o-transition: all 0.2s ease all;
  -ms-transition: all 0.2s ease all;
}
.bar:before {
  left: 50%;
}
.bar:after {
  right: 50%;
}

input:focus ~ .bar:before,
input:focus ~ .bar:after {
  width: 50%;
}

.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  -o-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

.group:focus-within {
  border-color: transparent;
}

@-webkit-keyframes inputHighlighter {
  from {
    background: $primary;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  from {
    background: $primary;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes inputHighlighter {
  from {
    background: $primary;
  }
  to {
    width: 0;
    background: transparent;
  }
}
