@import "../__common";

.paragraph {
    margin: 20px 40px;
    font: 19px $font-light;
    color: $text-main;
    line-height: 35px;
    text-overflow: ellipsis;

    &--is-danger {
        color: $danger;
    }
    &--is-success {
        color: $success;
    }
}
