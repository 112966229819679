@import "../__common";

.actions {
    display: grid;
    justify-content: end;
    margin-top: 8px;
    grid-template-columns: 1fr 1fr;
    width: 100%;

    &_left,
    &_right {
        display: grid;
        grid-template-columns: repeat(auto-fit, 50px);
    }
    &_left {
        margin-left: 20px;
        @include smartphone {
            margin-left: 0;
        }
        @include tablet {
            margin-left: 0;
        }
    }

    &_right {
        justify-content: end;
        margin-right: 8px;
        @include smartphone {
            margin-right: 0;
        }
        @include tablet {
            margin-right: 0;
        }
    }
}
