// COLORS
$white: #fff;
$divider: #ced6dc;
$success: #7be03e;
$danger: #ff6839;

// backgrounds
$primary: #339bf2;
$secondary: #ffac1b;
$tertiary: #738ea5;
$checkbox-default-border: $tertiary;

$primary-bg: $white;
$secondary-bg: #f3f3f3;

// TEXT
$primary-accent-dark: #20415d;
$secondary-accent-dark: #738ea5;
$tertiary-accent-dark: #339bf2;
$primary-accent-light: $white;
$secondary-accent-light: $white;
$tertiary-accent-light: $white;
$text-main: #4a4a4a;

// FONT FAMILIES
$font-normal: 'Montserrat';
$font-bold: "Montserrat Bold";
$font-light: "Montserrat Light"f;

$login-form-autofill-color: #e8f0fe;
$login-form-label-color: #9b9b9b;

* {
    font-family: $font-normal;
}

// MEDIA QUERIES
$tablet-width: 767px;
$desktop-width: 1024px;
$border-radius: 8px;
$box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);

@mixin smartphone {
    @media (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}

img {
    max-width: 100%;
    height: auto;
}
