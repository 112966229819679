@import "../__common";

h1,
h2,
h3 {
    color: $primary-accent-dark;
    font-family: $font-bold;

    & p {
        margin: 0;
    }
}

h1 {
    font-size: 32px;
    margin: 30px;
}

h2 {
    font-size: 24px;
    margin: 30px;
}

h3 {
    font-size: 16px;
    margin: 30px;
}
