@import "../__common";

.banner {
    display: grid;
    background: $white;
    width: 100%;
    border-radius: 6px;
    box-shadow: $box-shadow;

    &_header {
        display: grid;
        height: 54px;
        grid-template-columns: 100px 1fr 100px;
        border-bottom: 1px solid $divider;
        padding: 0 20px;
        @include smartphone() {
            padding: 0;
            grid-template-columns: 40px 1fr;
        }
        &-action {
            display: grid;
            cursor: pointer;
            justify-content: center;
            align-content: center;
            width: 20px;
        }
        svg {
            width: 42px;
            transform: rotateY(180deg);
            path {
                stroke: $tertiary;
            }
        }
        span {
            display: grid;
            align-self: center;
            justify-content: center;
        }
    }
    &_body {
        padding: 20px;
        text-align: center;

        &-content {
            text-align: initial;
            color: $text-main;

            & h2,
            & h3,
            & h4 {
                text-align: left;
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}
