@import "../__common";

.button {
    border: none;
    border-radius: 100px;
    font: 15px $font-normal;
    line-height: 50px;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    height: 50px;
    letter-spacing: 3px;
    cursor: pointer;
    display: grid;
    grid-template-columns: 50px 1fr 50px;
    grid-template-rows: 50px;
    width: max-content;
    white-space: nowrap;

    &--is-primary {
        color: $primary-accent-light;
        background-color: $primary;
        &.button--is-transparent {
            color: $primary;
            border: 1px solid $primary;
            background-color: $primary-accent-light;
            svg, path {
                stroke: $primary;
            }
        }
    }

    &--is-secondary {
        color: $secondary-accent-light;
        background-color: $secondary;
        &.button--is-transparent {
            color: $secondary;
            border: 1px solid $secondary;
            background-color: $secondary-accent-light;
            svg, path {
                stroke: $secondary;
            }
        }
    }

    &--is-tertiary {
        color: $tertiary-accent-light;
        background-color: $tertiary;
        &.button--is-transparent {
            color: $tertiary;
            border: 1px solid $tertiary;
            background-color: $tertiary-accent-light;
            svg, path {
                stroke: $tertiary;
            }
        }
    }

    &--is-success {
        color: $tertiary-accent-light;
        background-color: $success;
        &.button--is-transparent {
            color: $success;
            border: 1px solid $success;
            background-color: $tertiary-accent-light;
            svg, path {
                stroke: $success;
            }
        }
    }

    &--is-danger {
        color: $tertiary-accent-light;
        background-color: $danger;
        &.button--is-transparent {
            color: $danger;
            border: 1px solid $danger;
            background-color: $tertiary-accent-light;
            svg, path {
                stroke: $danger;
            }
        }
    }

    &--is-light {
        color: $tertiary-accent-light;
    }

    &-icon-left {
        display: grid;
        grid-column: 1;
        justify-self: left;
        margin-left: 7.5px;
        svg {
            position: relative;
            top: 7px;
            width: 35px;
            height: 35px;
            right: 1px;
            transform: rotate(180deg);
            left: -10px !important;
        }
    }

    &-text {
        display: grid;
        grid-column: 2;
    }

    &-icon-right {
        display: grid;
        grid-column: 3;
        justify-self: right;
        margin-right: 7.5px;
        svg {
            position: relative;
            top: 9px;
            width: 35px;
            height: 35px;
            right: 1px;
        }
    }

    &--is-slim {
        line-height: 40px;
        height: 40px;
        letter-spacing: 1px;
        svg {
            top: 4px;
        }
    }

    &--has-no-border {
        border: none !important;
        background-color: transparent !important;
        border-radius: unset;
    }

    &--has-icon-left {
        grid-template-columns: 67.5px 1fr 50px;
    }
    &--has-icon-right {
        grid-template-columns: 50px 1fr 67.5px;
    }
    &--has-icon-left-and-right {
        grid-template-columns: 67.5px 1fr 67.5px;
    }

    &--is-stretched {
        width: auto;
    }
}
