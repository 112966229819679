@import "../__common";

.question-form {
  display: grid;
  width: 100%;
  background-color: $secondary-bg;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  padding: 10px 0;
  .paragraph {
    text-align: center;
  }

  .answer-form-item {
    display: grid;
    align-items: center;
    &__body {
      display: grid;
      grid-template-columns: 1fr 100px 100px 50px;
      column-gap: 10px;
    }
    &__add-button {
      margin: auto;
    }
  }
  &__header, &__body, &__footer {
    display: grid;
  }

  &__header {
    padding: 25px 20px 25px 10px;
    grid-template-columns: 1fr 60px;

    .exercisable {
      grid-column-start: 1;
      grid-column-end: span 2;
      padding: 0 20px;
    }
  }

  &__footer {
    padding: 0 25px;
    h1 {
      margin: 0;
    }
  }
}
