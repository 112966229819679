@import "../__common";

.list {
    display: grid;
    grid-template-rows: 1fr;

    &-item {
        padding: 20px 0;
        border-bottom: 1px solid $divider;
        &:last-child {
            border: none;
        }
    }
}
