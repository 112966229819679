@import "../__common";
@import "../atoms/blockquote";

.ck {
    .legal-box {
        @extend blockquote;

        &-headline {
            font-weight: bold;
            margin: 20px 0;
            background: $white;

            &[contenteditable=true]:before {
                font-size: small;
                color: $divider;
                content: 'Headline';
                pointer-events: none;
                display: block; /* For Firefox */
            }
        }
        &-text {
            margin: 0;
            background: $white;

            & p {
                margin-left: 0;
                margin-right: 0;
            }

            &[contenteditable=true]:before {
                font-size: small;
                color: $divider;
                content: 'Text';
                pointer-events: none;
                display: block; /* For Firefox */
            }
        }
    }

    .soft-hyphen {
        border: 1px dotted $primary-accent-dark;
        display: inline-block;
        padding: 0 5px;

        &:after {
            content: '-';
        }
    }
}
