@import "../__common";

.divider {
    margin: 0 auto;
    border: none;
    &--is-small {
        width: 100px;
        border-bottom: 3px solid $divider;
    }
    &--is-medium {
        width: 90%;
        border-bottom: 2px solid $divider;
    }
    &--is-full {
        width: 100%;
        border-bottom: 1px solid $divider;
    }
}
