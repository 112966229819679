@import "../__common";

.list-item {
  display: grid;

  &--is-clickable {
    cursor: pointer;
  }

  &_content {
    display: grid;
    @include desktop {
      padding: 0 20px;
      grid-template-columns: 40px 2fr 1fr;
    }
    @include tablet {
      padding: 0 20px;
      grid-template-columns: 40px 2fr 1fr;
      .button {
        display: none;
      }
    }
    @include smartphone {
      grid-template-columns: 40px 1fr;
      .button {
        display: none;
      }
    }

    &-description {
      display: grid;
      grid-template-rows: auto;
      padding: 0 10px;
      align-content: center;
      text-align: left;

      p {
        margin: 0;
        word-break: break-word;
      }
    }
  }

  &_full-width {
    display: block !important;
  }

  @include smartphone() {
    &--is-selected {
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      -ms-transition: all 0.5s ease;
      transition: all 0.5s ease;
      &.list-item--is-primary {
        background: $primary;
      }

      &.list-item--is-secondary {
        background: $secondary;
      }

      &.list-item--is-tertiary {
        background: $tertiary;
      }

      &.list-item--is-success {
        background: $success;
      }

      &.list-item--is-danger {
        background: $danger;
      }
    }
  }

  .checkbox {
    align-self: unset;
    margin-top: 4px;
  }
}
