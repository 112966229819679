@import "../__common";

.grid {
    display: grid;
    width: 100%;
    hyphens: auto;
    word-break: break-word;

    &--is-col-12 {
        grid-template-columns: 1fr;
    }

    &--is-col-6 {
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
        @include smartphone {
            grid-template-columns: 1fr;
            column-gap: 20px;
        }
    }
}
