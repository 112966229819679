@import "../__common";

.CircularProgressbar {
    display: grid;
    width: 40px;
    align-self: center;
    &-path {
        stroke: $primary;
    }
    &-trail {
        stroke: $secondary-accent-dark;
    }
}
