@import "../atoms/blockquote";

.learning-content {
    width: 100%;

    h1,
    textarea {
        text-align: center;
    }
    textarea {
        font-size: 32px;
    }
    div textarea {
        text-align: initial;
        font-size: initial;
    }


    .legal-box {
        @extend blockquote;
        font-size: initial;

        &-headline {
            font-weight: bold;
            margin: 20px 0;
        }
        &-text {
            margin: 0;

            & p {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    .paragraph {
        display: flex;
        flex-direction: column;

        img {
            padding: 15px 0;
            align-self: center;
        }

    }
}
