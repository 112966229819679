@import "../__common";

.learning-area-card {
    display: grid;
    text-align: center;
    margin: 0 0 20px 0;
    background: $white;
    border-radius: 6px;
    box-shadow: $box-shadow;
    width: 100%;

    &-header {
        display: grid;
        height: 70px;
        padding: 0 50px;
        border-bottom: 1px solid $divider;
        align-content: center;
        justify-content: left;
    }
    &-body {
        padding: 15px;
        word-break: break-word;
        hyphens: auto;
        &__points {
            margin: 0 30px;
        }
    }

    .text {
        font-size: 11px;
        &.text-with-marker {
            text-transform: uppercase;
            font-family: $font-light;
        }
    }
    .group-border {
        margin: 25px;
        textarea {
            font-size: 32px;
            text-align: center;
            color: $primary-accent-dark;
            font-family: $font-bold;
            font-weight: bold;
        }
    }
    .divider {
        margin: 25px auto;
    }

    .button {
        margin: auto auto 10px auto;
    }
}
